export const renderGoogleOT = (origin) => {
  window.handleCredentialResponse = (response) => {
    const url = `https://users.wix.com/signin#state=googleOT&id_token=${response.credential}`;
    window.location.href = url;
  };

  const googleOTScript = document.createElement('script');
  googleOTScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
  googleOTScript.setAttribute('async', '');
  document.head.appendChild(googleOTScript);

  const container = document.createElement('div');
  container.setAttribute('id', 'g_id_onload');
  container.setAttribute(
    'data-client_id',
    '352188552355.apps.googleusercontent.com'
  );
  container.setAttribute('data-callback', 'handleCredentialResponse');
  container.setAttribute('data-moment-callback', 'sendBi');
  container.setAttribute('data-cancel_on_tap_outside', 'false');

  container.setAttribute('data-prompt_parent_id', 'g_id_onload');
  document.body.appendChild(container);

  const body = new URLSearchParams({ app_url: 'wix.com', view: origin });

  googleOTScript.onload = function () {
    fetch(`https://frog.wix.com/hls2?src=6&evid=186&_=${new Date().getTime()}`, {
      method: 'post',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });
  };
};

export const removeGOT = () => {
  window.google.accounts.id.cancel();
}
