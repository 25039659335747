import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Box, Text } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { ROUTES } from '../../routes';
import { ISignupFlows, SIGNUP_FLOWS } from '../../stores/signup';
import { keys } from '../../translationsKeys';
import { AppContextProvider } from '../AppLoader';
import { AuthPage, AuthPageContextProvider } from '../AuthPage/AuthPage';
import { ButtonWithLoader } from '../ButtonWithLoader/ButtonWithLoader';
import { InputFieldWithLabel } from '../InputField/InputField';
import { PasswordFieldWithLabel } from '../PasswordField/PasswordField';
import { PoliciesContainer } from '../PoliciesContainer';
import { Recaptcha } from '../Recaptcha/Recaptcha';
import { SocialAuthIconsWithApple } from '../SocialAuth/SocialAuth';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import { renderGoogleOT, removeGOT } from '../OneTap/GoogleOT';
import s from './Signup.scss';

export const Signup: React.FC = observer(() => {
  const {
    rootStore: { signupStore, displayStore },
  } = useContext(AppContextProvider);

  const { t } = useTranslation();
  // Should apply "autoFocus" only after wsr fixes their bug in InputWithLabel https://wix.monday.com/boards/2436042988/pulses/3945043595
  const shouldFocusFirstEmail = signupStore.flow === SIGNUP_FLOWS.DEFAULT;
  
  useEffect(() => {
    if (!displayStore.isMobile) {
      signupStore.isEmailSignupMode = false;
    }
    signupStore.shouldDisplayOneTap && renderGoogleOT('signup_page');

  }, [displayStore.isMobile, signupStore.shouldDisplayOneTap])

  useEffect(() => {
    return () => {
      signupStore.shouldDisplayOneTap && removeGOT();
    }
  }, [])
  
  return (
    <AuthPageContextProvider.Provider value={{ store: signupStore }}>
      <Box
        direction="vertical"
        width="100%"
        align="center"
        marginBottom={!displayStore.isMobile ? '40px' : undefined}
      >
        <AuthPage
          container={{
            dataHook: dataHooks.login.container,
            mainHeaderProps: {
              showBackButton: signupStore.flow === SIGNUP_FLOWS.FROM_LOGIN,
            },
          }}
        >
          <AuthPage.Header dataHooks={dataHooks.signup}>
            <SignupSubTitle flow={signupStore.flow} />
          </AuthPage.Header>
          {signupStore.isEmailSignupMode ? (
            <EmailSignup shouldFocusFirstEmail={shouldFocusFirstEmail} />
          ) : (
            <AuthPage.BodyWithSocialButtons
              dividerText={t(
                keys[
                  displayStore.isMobile
                    ? 'signup.divider.use.email.mobile'
                    : 'signup.divider'
                ],
              )}
            >
              {displayStore.isMobile ? ( // Show only email field on mobile
                <Box
                  direction={displayStore.sideBySideForm.direction}
                  width={displayStore.sideBySideForm.width}
                >
                  <Box
                    width={displayStore.socialButtonsWidth}
                    direction="vertical"
                    verticalAlign="middle"
                  >
                    <InputFieldWithLabel
                      required
                      onFocus={() => {
                        signupStore.isEmailSignupMode = true;
                      }}
                      label={t(keys['signup.email.label'])}
                      type="email"
                      autocomplete="email"
                      marginBottom={0}
                      border="bottomLine"
                    />
                  </Box>
                </Box>
              ) : (
                <EmailSignup shouldFocusFirstEmail={shouldFocusFirstEmail} />
              )}
            </AuthPage.BodyWithSocialButtons>
          )}
        </AuthPage>
        <AuthPage.Policies>
          <PoliciesContainer.Expanded />
        </AuthPage.Policies>
      </Box>
    </AuthPageContextProvider.Provider>
  );
});

const EmailSignup: React.FC<{ shouldFocusFirstEmail?: boolean }> = observer(
  ({ shouldFocusFirstEmail }) => {
    const {
      rootStore: { signupStore, displayStore, socialAuthStore },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    const shouldRepeatPassword = !displayStore.isMobile;

    useEffect(() => {
      return ()=>{
        signupStore.clear()
        signupStore.flow = SIGNUP_FLOWS.DEFAULT
      }
    }, [])
    
    return (
      <Box
        direction={displayStore.sideBySideForm.direction}
        width={displayStore.sideBySideForm.width}
      >
        <Box
          width={displayStore.socialButtonsWidth}
          direction="vertical"
          verticalAlign="middle"
          textAlign='left'
          gap={1}
        >
          <InputFieldWithLabel
            required
            autoFocus={shouldFocusFirstEmail}
            onBlur={() => {
              signupStore.updateEmailFieldMatchError();
            }}
            onChange={signupStore.emailField.onChange}
            dataHook={dataHooks.signup.email}
            formField={signupStore.emailField}
            label={t(keys['signup.email.label'])}
            type="text"
            autocomplete="email"
            marginBottom={0}
            border="bottomLine"
          />
          <InputFieldWithLabel
            required
            autoFocus={!shouldFocusFirstEmail}
            disablePaste
            onChange={signupStore.emailRepeatField.onChange}
            onBlur={() => {
              signupStore.updateEmailFieldMatchError();
            }}
            dataHook={dataHooks.signup.confirmEmail}
            formField={signupStore.emailRepeatField}
            label={t(keys['signup.emailRepeat.label'])}
            type="text"
            autocomplete="email"
            marginBottom={0}
            border="bottomLine"
          />
          <PasswordFieldWithLabel
            required
            onBlur={() => {
              signupStore.updatePasswordFieldMatchError();
            }}
            dataHook={dataHooks.signup.password}
            formField={signupStore.passwordField}
            onChange={signupStore.passwordField.onChange}
            label={t(keys['signup.password.label'])}
            autocomplete="new-password"
            showStrength
            isMobile={displayStore.isMobile}
            showVisibility={!!signupStore.passwordField.value.length}
            value={signupStore.passwordField.value}
            marginBottom={0}
            border="bottomLine"
            onFocus={(e)=>{
              e.target.scrollIntoView();
            }}
          />
          {shouldRepeatPassword && (
            <PasswordFieldWithLabel
              required
              disablePaste
              onBlur={() => signupStore.updatePasswordFieldMatchError()}
              dataHook={dataHooks.signup.confirmPassword}
              formField={signupStore.passwordRepeatField}
              label={t(keys['signup.passwordRepeat.label'])}
              autocomplete="new-password"
              showVisibility={!!signupStore.passwordRepeatField.value.length}
              value={signupStore.passwordRepeatField.value}
              marginBottom={0}
              border="bottomLine"
              onEnterPressed={signupStore.signup}
            />
          )}
          <Recaptcha />
          <Box
            marginBottom={1}
            marginTop={socialAuthStore.isUnsupportedAgentForSocialAuth ? 4 : 0}
            align={
              socialAuthStore.isUnsupportedAgentForSocialAuth
                ? 'center'
                : 'space-between'
            }
            direction={
              socialAuthStore.isUnsupportedAgentForSocialAuth
                ? 'vertical'
                : 'horizontal'
            }
          >
            <ButtonWithLoader
              className={classNames(s.a11yFocus, s.border)}
              type="submit"
              onMouseDown={
                displayStore.isMobile ? signupStore.signup : undefined
              }
              onClick={displayStore.isMobile ? undefined : signupStore.signup}
              dataHook={dataHooks.signup.submit}
              showLoader={signupStore.isLoading}
              skin={displayStore.isMobile ? 'standard' : 'inverted'}
            >
              {t(keys['signup.submit'])}
            </ButtonWithLoader>
            {displayStore.isMobile &&
              !socialAuthStore.isUnsupportedAgentForSocialAuth && (
                <Box verticalAlign="middle" marginBottom={2}>
                  <Text size="small">{t(keys['signup.divider.mobile'])}</Text>
                </Box>
              )}
            {displayStore.isMobile && (
              <Box direction="vertical" verticalAlign="top">
                <SocialAuthIconsWithApple />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);

const SignupSubTitle: React.FC<{ flow: ISignupFlows }> = ({ flow }) => {
  const location = useLocation();
  const {
    rootStore: {
      signupStore,
      emailStepStore: { loginEmailStepStore },
    },
  } = useContext(AppContextProvider);

  if (flow === SIGNUP_FLOWS.DEFAULT) {
    return (
      <ThemedText>
        <Trans i18nKey={keys['emailStep.signup.subTitle']}>
          Already have an account?
          <ThemedTextButton
            onClick={() => {
              loginEmailStepStore.onShowLoginEmailStep();
              signupStore.reportSwitchToLogin();
            }}
            as={Link}
            to={ROUTES.EMAIL_STEP + location.search}
            dataHook={dataHooks.signup.switchToLoginBtn}
            skin="standard"
          >
            Log In
          </ThemedTextButton>
        </Trans>
      </ThemedText>
    );
  }

  return <></>;
};
