import { HttpClient } from '@wix/http-client';
import {
  PasswordPolicy,
  PolicyType,
  ValidateMyPasswordResponse,
} from '@wix/ambassador-iam-password-v1-password/types';
import {
  getMyPasswordPolicy,
  validateMyPassword,
  getPasswordPolicyByForgotPasswordEmailToken,
} from '@wix/ambassador-iam-password-v1-password/http';

export class PasswordService {
  private httpClient: HttpClient = new HttpClient();
  public async getPasswordPolicy(): Promise<PasswordPolicy | undefined> {
    try {
      const response = await this.httpClient.request(getMyPasswordPolicy({}));
      if (response?.data?.passwordPolicy?.policyType !== PolicyType.CUSTOM) {
        return undefined;
      }
      return response?.data?.passwordPolicy;
    } catch (error) {
      return undefined;
    }
  }

  public async getPasswordPolicyFromResetToken(
    resetToken: string
  ): Promise<PasswordPolicy | undefined> {
    try {
      const response = await this.httpClient.request(
        getPasswordPolicyByForgotPasswordEmailToken({ token: resetToken })
      );
      if (response?.data?.passwordPolicy?.policyType !== PolicyType.CUSTOM) {
        return undefined;
      }
      return response?.data?.passwordPolicy;
    } catch (error) {
      return undefined;
    }
  }

  public async validateNewPassword(
    passwordPolicy: PasswordPolicy,
    newPassword: string
  ): Promise<ValidateMyPasswordResponse> {
    const res = await this.httpClient.request<ValidateMyPasswordResponse>(
      validateMyPassword({
        passwordPolicy,
        password: newPassword,
      })
    );
    return res?.data;
  }
}
