import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { createContext, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { Box, Loader, Text, TextButton } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { ROUTES } from '../../routes';
import { CustomizedEmailStepStore } from '../../stores/emailStep';
import { keys } from '../../translationsKeys';
import { AppContextProvider } from '../AppLoader';
import { AuthDivider } from '../AuthPage/AuthPage';
import authPageStyle from '../AuthPage/AuthPage.scss';
import { ButtonWithLoader } from '../ButtonWithLoader/ButtonWithLoader';
import { DialogFormContainer } from '../DialogContainer';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { InputFieldWithLabel } from '../InputField';
import { PoliciesContainer } from '../PoliciesContainer';
import { Recaptcha } from '../Recaptcha/Recaptcha';
import {
  SocialAuth,
  SocialAuthIconsWithApple,
} from '../SocialAuth/SocialAuth';
import { ThemedTextButton } from '../ThemedComponents';
import s from './EmailStep.scss';
import { ChevronRight } from '@wix/wix-ui-icons-common';
import { renderGoogleOT, removeGOT } from '../OneTap/GoogleOT';

export interface EmailStepProps {
  showForgotEmailLink?: boolean;
  showContinueWithSsoLink?: boolean;
  policiesElement?: React.ReactNode;
}

interface EmailStepContext {
  store: CustomizedEmailStepStore;
}

export const EmailStepContextProvider = createContext<EmailStepContext>(
  {} as any
);

export const EmailStepHeader: React.FC = observer(({ children }) => {
  const { store } = useContext(EmailStepContextProvider);
  const {
    rootStore: {
      displayStore: {
        isMobile,
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  
  return (
    <DialogHeader
      dataHook={dataHooks.emailStep.header}
      className={s.emailStepHeader}
    >
      <DialogHeader.Title
        dataHook={dataHooks.emailStep.title}
        className={s.title}
      >
        {t(keys[store.titleKey])}
      </DialogHeader.Title>
      {children}
    </DialogHeader>
  );
});

const MobileEmailStepBody: React.FC<EmailStepProps> = observer(
  ({ children }) => {
    const {
      rootStore: {
        displayStore: { sideBySideForm, isMobile },
        socialAuthStore,
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    return (
      <React.Fragment>
        {children}
        <Box
          gap={2}
          direction="horizontal"
          align="space-between"
          width={sideBySideForm.width}
          marginTop={3}
        >
          {!isMobile ||
            (!socialAuthStore.isUnsupportedAgentForSocialAuth && (
              <Box direction="vertical" verticalAlign="middle" marginBottom={3}>
                <Text>{t('emailStep.or.login.with')}</Text>
              </Box>
            ))}
          <SocialAuthIconsWithApple />
        </Box>
      </React.Fragment>
    );
  }
);

const EmailStepBody: React.FC<EmailStepProps> = observer(
  ({ showContinueWithSsoLink, children }) => {
    const {
      rootStore: {
        displayStore: {
          socialButtonsWidth,
          isWixEducation,
          sideBySideForm,
          isMobile,
        },
        emailStepStore,
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    return (
      <React.Fragment>
        {children}
        <AuthDivider
          className={classNames({ [authPageStyle.horizontal]: !isMobile })}
        />
        <Box
          gap={2}
          direction="vertical"
          align="right"
          width={sideBySideForm.width}
          verticalAlign="middle"
        >
          <SocialAuth />
          {!isWixEducation && showContinueWithSsoLink && (
            <Box width={socialButtonsWidth} align="center">
              <TextButton
                className={s.a11yFocus}
                onClick={emailStepStore.onClickContinueWithSso}
                size="small"
                underline="none"
                dataHook={dataHooks.emailStep.continueToSsoLink}
              >
                {t(keys['emailStep.sso.button'])}
              </TextButton>
            </Box>
          )}
        </Box>
      </React.Fragment>
    );
  }
);

const EmailStepBodyReverse: React.FC<EmailStepProps> = observer(
  ({ showContinueWithSsoLink, children }) => {
    const {
      rootStore: {
        displayStore: {
          socialButtonsWidth,
          isWixEducation,
          sideBySideForm,
          isMobile,
        },
        emailStepStore,
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    return (
      <React.Fragment>
        <Box
          gap={2}
          direction="vertical"
          align="right"
          width={sideBySideForm.width}
          verticalAlign="middle"
        >
          <SocialAuth />
          {!isWixEducation && showContinueWithSsoLink && (
            <Box width={socialButtonsWidth} align="center">
              <TextButton
                className={s.a11yFocus}
                onClick={emailStepStore.onClickContinueWithSso}
                size="small"
                underline="none"
                dataHook={dataHooks.emailStep.continueToSsoLink}
              >
                {t(keys['emailStep.sso.button'])}
              </TextButton>
            </Box>
          )}
        </Box>
        <AuthDivider
          text={t(keys['login.divider.mobile'])}
          className={classNames({ [authPageStyle.horizontal]: !isMobile })}
        />
        {children}
      </React.Fragment>
    );
  }
);

const _EmailStep: React.FC<EmailStepProps> = observer(
  ({ showForgotEmailLink }) => {
    const {
      rootStore: {
        displayStore: { socialButtonsWidth, sideBySideForm, isMobile },
        emailStepStore,
        userDataStore,
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();

    useEffect(() => {
      emailStepStore.emailField.clear();
    }, []);

    return (
      <Box width={sideBySideForm.width} paddingBottom={0}>
        <Box width={socialButtonsWidth} direction="vertical" paddingBottom={0}>
          {isMobile && !emailStepStore.isEmailLoginMobileMode ? (
            <InputFieldWithLabel
              label={t(keys['emailStep.email.label'])}
              type="email"
              marginBottom={0}
              wrapperClassName={s.emailInput}
              dataHook={dataHooks.emailStep.emailInput}
              border="bottomLine"
              onFocus={() => {
                emailStepStore.isEmailLoginMobileMode = true;
              }}
            />
          ) : (
            <React.Fragment>
                <InputFieldWithLabel
                  required
                  autoFocus
                  formField={emailStepStore.emailField}
                  ariaLabel={t(keys['emailStep.signup.or.login'])}
                  label={t(keys['emailStep.email.label'])}
                  type="text"
                  name="email"
                  autocomplete="email"
                  marginBottom={isMobile ? 2 : 0}
                  wrapperClassName={s.emailInput}
                  dataHook={dataHooks.emailStep.emailInput}
                  border="bottomLine"
                />
              <Recaptcha />
              <Box
                direction="vertical"
                align={
                  !emailStepStore.isEmailLoginMobileMode ? 'left' : undefined
                }
                gap={emailStepStore.isEmailLoginMobileMode ? 0 : 4}
              >
                {showForgotEmailLink && !isMobile && (
                  <ThemedTextButton
                    className={s.a11yFocus}
                    onClick={emailStepStore.onClickForgotEmail}
                    size="small"
                    underline="always"
                    dataHook={dataHooks.emailStep.forgotEmailLink}
                    as="a"
                    href={emailStepStore.getAccountRecoveryLink('email')}
                  >
                    {t(keys['emailStep.forgotEmail.button'])}
                  </ThemedTextButton>
                )}
                <ButtonWithLoader
                  className={classNames(s.submitButton, s.a11yFocus, s.border)}
                  onClick={() => emailStepStore.submit()}
                  showLoader={emailStepStore.isLoading}
                  dataHook={dataHooks.emailStep.submitButton}
                  type="submit"
                  fullWidth={emailStepStore.isEmailLoginMobileMode}
                  skin={
                    emailStepStore.isEmailLoginMobileMode
                      ? 'standard'
                      : 'inverted'
                  }
                  suffixIcon={
                    !emailStepStore.isLoading ? <ChevronRight /> : undefined
                  }
                >
                  {t(keys['emailStep.submit'])}
                </ButtonWithLoader>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Box>
    );
  }
);

const getEmailStepBody = (
  showForgotEmailLink: boolean,
  showContinueWithSsoLink: boolean,
  isEmailLoginMobileMode: boolean,
  isMobile: boolean
) => {
  if (isEmailLoginMobileMode) {
    return (
      <MobileEmailStepBody>
        <_EmailStep showForgotEmailLink={false} />
      </MobileEmailStepBody>
    );
  }
  if (isMobile) {
    return (
      <EmailStepBodyReverse showContinueWithSsoLink={showContinueWithSsoLink}>
        <_EmailStep showForgotEmailLink={false} />
      </EmailStepBodyReverse>
    );
  }
  return (
    <EmailStepBody showContinueWithSsoLink={showContinueWithSsoLink}>
      <_EmailStep showForgotEmailLink={showForgotEmailLink} />
    </EmailStepBody>
  );
};

const EmailStepWrapper: React.FC<EmailStepProps> = observer(
  ({
    children,
    showForgotEmailLink = false,
    showContinueWithSsoLink = false,
    policiesElement,
  }) => {
    const {
      rootStore: {
        displayStore: {
          isFullScreenMode,
          sideBySideForm,
          authFormWidth,
          isMobile,
        },
        emailStepStore,
      },
    } = useContext(AppContextProvider);
    return (
      <Box
        direction="vertical"
        align="center"
        width="100%"
        marginBottom={!isMobile ? '40px' : undefined}
      >
        <DialogFormContainer
          dataHook={dataHooks.emailStep.container}
          mainHeaderProps={{ showBackButton: false }}
        >
          {children}
          <Box
            direction={sideBySideForm.direction}
            maxWidth={authFormWidth}
            width="100%"
            height={sideBySideForm.height('small')}
          >
            {getEmailStepBody(
              showForgotEmailLink,
              showContinueWithSsoLink,
              emailStepStore.isEmailLoginMobileMode,
              isMobile
            )}
          </Box>
        </DialogFormContainer>
        <Box
          align="center"
          direction="vertical"
          width="100%"
          className={classNames(s.policiesContainer, {
            [s.modalPolicies]: !isFullScreenMode,
          })}
        >
          <PoliciesContainer>
            {policiesElement || <PoliciesContainer.Expanded />}
          </PoliciesContainer>
        </Box>
      </Box>
    );
  }
);

const EmailStep = Object.assign(EmailStepWrapper, {
  EmailStepHeader,
});

const LoginEmailStepDialog: React.FC = observer(() => {
  const {
    rootStore: {
      loginStore,
      emailStepStore: { loginEmailStepStore, signupEmailStepStore },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();

  return (
    <EmailStepContextProvider.Provider value={{ store: loginEmailStepStore }}>
      <EmailStep
        showForgotEmailLink={true}
        showContinueWithSsoLink={true}
        policiesElement={<PoliciesContainer.Minimal />}
      >
        <EmailStep.EmailStepHeader>
          {loginEmailStepStore.subtitleKey && (
            <DialogHeader.SubTitleSmall>
              {t(keys[loginEmailStepStore.subtitleKey])}
            </DialogHeader.SubTitleSmall>
          )}
          {!loginStore.shouldBlockEditorXSignup() && (
            <DialogHeader.SubTitleSmall id={s["loginSubtitle"]}>
              <Trans i18nKey={keys['emailStep.login.subTitle']}>
                Dont have an account?
                <ThemedTextButton
                  onClick={() => {
                    signupEmailStepStore.onShowSignupEmailStep();
                    loginStore.reportSwitchToSignup();
                  }}
                  dataHook={dataHooks.loginEmailStep.switchToSignupBtn}
                  skin="standard"
                >
                  Sign Up
                </ThemedTextButton>
              </Trans>
            </DialogHeader.SubTitleSmall>
          )}
        </EmailStep.EmailStepHeader>
      </EmailStep>
    </EmailStepContextProvider.Provider>
  );
});

export const EmailStepDialog: React.FC = observer(() => {
  const location = useLocation();
  const {
    rootStore: {
      emailStepStore: {
        isFetchingByDefaultEmail,
        loginEmailStepStore,
      },
    },
  } = useContext(AppContextProvider);
  const customizedEmailStep = loginEmailStepStore.showLoginEmailStep ? (
    <LoginEmailStepDialog />
  ) : (
    <Redirect to={ROUTES.SIGNUP_PASSWORD + location.search} />
  );
  if (isFetchingByDefaultEmail) {
    return (
      <Box width="100%" height="100%" align="center" verticalAlign="middle">
        <Loader />
      </Box>
    );
  }
  return customizedEmailStep
});
